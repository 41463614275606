import { navigate, PageProps } from "gatsby";

import { getWindow } from "../../../utils/browser-features";

const Page = ({ params }: PageProps): JSX.Element => {
  const w = getWindow();
  const originalQueryParams = w ? new URLSearchParams(w.location?.search).toString() : null;
  const nextUrl = `/results/${params.resultUuid}/?${originalQueryParams}`;
  if (w) {
    void navigate(nextUrl);
  }

  return <></>;
};

export default Page;
